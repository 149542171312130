import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { contact, clearContactMessage } from "../actions";

import $ from "jquery";

class PreRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //contactUsMessage: null,
      //contactUsError: null,
    };
    window.scrollTo(0, 0); // scroll page to top
    this.contactUsForm = this.contactUsForm.bind(this);
  }

  componentDidMount() {
    // let language;
    // if (this.props.lang === "en") language = "english";
    // if (this.props.lang === "fr") language = "french";
    var registrationForm =
    this.props.lang === "en"
    ? "/assets/pdf/REGISTRATION_FORM.PDF"
    : "/assets/pdf/BULLETIN_INSCRIPTION.PDF";
  }

  contactUsForm(values) {
    values["language"] = this.props.lang;
    //console.log('Init submit form'+JSON.stringify(values)+' lang'+this.props.lang);
    //{"name":"test","email":"test@gmail.com","message":"asdfsdfsdfdsf"}
    $("#contactus-loader").show();
    this.refs.sbmt.setAttribute("disabled", "disabled");

    //console.log('Inside contactUsForm '+values.email);
    this.props.contact(values, this.refs.sbmt, (response) => {
      //console.log('Response '+response);
      if (response === "success") {
        this.props.reset("contactUsValidation");
        // on successfull signup show login form
        $("#contactus-loader").hide();
        // after successfully submit redirect to respective page
        setTimeout(function () {
          window.location = "/";
        }, 10000);
      }
    });
  }

  renderInput(field) {
    return (
      <div className="form-group">
        <label>{field.label}</label>
        <input
          type={field.type}
          className="form-control"
          placeholder={field.placeholder}
          autoComplete="off"
          {...field.input}
        />
        {field.meta.touched && field.meta.error && (
          <small className="text-danger">{field.meta.error}</small>
        )}
      </div>
    );
  }
  renderTextarea(field) {
    return (
      <div className="form-group">
        <label>{field.label}</label>
        <textarea
          type={field.type}
          className="form-control md-textarea"
          placeholder={field.placeholder}
          {...field.input}
        ></textarea>
        {field.meta.touched && field.meta.error && (
          <small className="text-danger">{field.meta.error}</small>
        )}
      </div>
    );
  }

  errorMessage() {
    //console.log(this.props.contactUsError+' --  '+this.props.contactUsMessage+' props '+JSON.stringify(this.state));
    if (
      this.props.contactUsError !== null &&
      this.props.contactUsError !== ""
    ) {
      return (
        <div className="col-md-12 alert alert-danger" role="alert">
          {this.props.contactUsError}
        </div>
      );
    }
    if (
      this.props.contactUsMessage !== null &&
      this.props.contactUsMessage !== ""
    ) {
      return (
        <div className="col-md-12 alert alert-success" role="alert">
          {this.props.contactUsMessage}
        </div>
      );
    }
    // used to reset the error or sucess message

    //setTimeout(function(){ this.props.clearContactMessage(); }, 10000);
    // setTimeout(() => {
    //   this.props.clearContactMessage();
    // }, 18000);
  }

  render() {
    //const { intl } = this.props;
    const firstname = (
      <FormattedMessage
        id="contact.us.name"
        defaultMessage="First name, Name *"
      />
    );
    const emailaddress = (
      <FormattedMessage
        id="contact.us.emailaddress"
        defaultMessage="Email address *"
      />
    );
    const yourmessage = (
      <FormattedMessage
        id="contact.us.message"
        defaultMessage="Your message *"
      />
    );
    const phone = (
      <FormattedMessage
        id="contact-us.phone"
        defaultMessage="Your phone *"
      />
    );
    const registration_date = (
      <FormattedMessage
        id="contact-us.date"
        defaultMessage="Registration Date *"
      />
    );
    return (
      <React.Fragment>
        <section id="contact-page" className="mb-4 pages" style={{ paddingTop:"120px" }}>
          <div className="container">
            {this.props.contactUsMessage === null ? (
              <div className="col-md-8" >
                <h4 className="h1-responsive font-weight-bold text-center my-4">
                  <FormattedMessage
                    id="contact.pre.registration"
                    defaultMessage="Contact us"
                  />
                </h4>
                <p className="text-center w-responsive mx-auto">
                 
                    <FormattedMessage
                      id="contact_us_sub_title"
                      defaultMessage="Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you soon."
                    />
                 
                </p>
                <p className="text-center w-responsive">
                  <FormattedMessage
                    id="contact_us_sub_title2"
                    defaultMessage="Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you soon."
                  />
                </p>
              </div>
            ) : (
              ""
            )}
       
              <div className="Home-btn show-desktop" style={{ left:"75%", top:"50px" }}>
                <Link
                  className="btn btn-primary p-link"
                  style={{
                    background: "white",
                    padding: "10px 10px",
                    pointerEvents: "none",
                    marginTop:"24%",
                    marginBottom:"14%",
                  }}
                >
                  <div class="client-btn">
                    <img
                      className="logo"
                      src="assets/images/LOGO3.jpg"
                    ></img>
                    <b>
                      <p style={{ marginBottom: "0px", color: "black", fontSize:"11px" }}>
                        {" "}
                        <FormattedHTMLMessage
                          id="home.titlebtn"
                          defaultMessage="FAQ"
                        />
                      </p>
                    </b>
                  </div>
                </Link>
                
                <a href={this.props.lang === "en"? "/assets/pdf/REGISTRATION_FORM.PDF"
                    : "/assets/pdf/BULLETIN_INSCRIPTION.PDF"} target="_blank">
                  <button className="info-btn" >
                    {" "}
                    <FormattedHTMLMessage
                      id="contact-us.registration.title"
                      defaultMessage="FAQ"
                    />
                  </button>
                </a>
                <a href={this.props.lang === "en"? "/assets/pdf/TRAINING_PROGRAM.PDF"
                    : "/assets/pdf/PROGRAMME_FORMATION.PDF"} target="_blank">
                  <button className="info-btn" style={{ marginTop:"12%" }}>
                    {" "}
                    <FormattedHTMLMessage
                      id="contact-us.training.title"
                      defaultMessage="FAQ"
                    />
                  </button>
                </a>
                <p className="text-center w-responsive mt-3">
                  <FormattedMessage
                    id="contact-us.partner.logo"
                    defaultMessage="Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you soon."
                  /><br/>
                   <img
                      className="logo"
                      src="assets/images/partner_logo.jpg"
                      style={{ width:"47%" }}
                    ></img>
                </p>
              </div>
 
            <div className="row">
              {this.props.contactUsMessage != null ? (
                <div className="col-md-12 p-4 m-0 text-center">
                  {this.errorMessage()}
                  <div className="col-sm-12 mx-auto text-center mb-4 pb-45">
                    <Link to="/" className="btn btn-warning mt-3 mx-3">
                      <FormattedMessage
                        id="goto_home"
                        defaultMessage="HOME PAGE"
                      />
                    </Link>
                  </div>
                </div>
              ) : (
                <form
                  id="contact-form"
                  onSubmit={this.props.handleSubmit((e) =>
                    this.contactUsForm(e)
                  )}
                  className="row w-100"
                >
                  <div className="col-lg-8 col-md-10 p-0 m-0">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="md-form mb-0">
                          <Field
                            name="name"
                            type="text"
                            label={firstname}
                            placeholder=""
                            className="form-control"
                            component={this.renderInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="md-form mb-0">
                          <Field
                            name="email"
                            type="email"
                            label={emailaddress}
                            placeholder=""
                            className="form-control"
                            component={this.renderInput}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                          <div className="col-md-12">
                              <div className="md-form mb-0">
                                  <Field
                                    name="subject"
                                    type="text"
                                    label="Subject"
                                    className="form-control"
                                    component={this.renderInput}
                                  />
                              </div>
                          </div>
                      </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="md-form">
                          <Field
                            name="message"
                            type="textarea"
                            label={yourmessage}
                            placeholder=""
                            className="form-control md-textarea"
                            rows="2"
                            component={this.renderTextarea}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="md-form mb-0">
                          <Field
                            name="phone"
                            type="text"
                            label={phone}
                            placeholder=""
                            className="form-control"
                            component={this.renderInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="md-form mb-0">
                          <Field
                            name="registration_date"
                            type="date"
                            label={registration_date}
                            placeholder=""
                            className="form-control"
                            component={this.renderInput}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 ml-3 mr-5" style={{ background: "yellow", padding: "6px 18px" }}>
                        <div className="md-form mb-0">
                          <input
                            name="approve"
                            type="checkbox"
                          />  
                          <FormattedMessage
                          id="contact-us.confirm"
                          defaultMessage="Confirm *"
                          />
                         <FormattedMessage
                          id="contact-us.confirm.desc"
                          defaultMessage="Confirm *"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <div
                        className="spinner-border text-primary m-auto"
                        role="status"
                        id="contactus-loader"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div className="col-md-12 text-center mt-3">
                      <button
                        type="submit"
                        name="send-query"
                        id="send-query"
                        tabIndex="4"
                        className="btn btn-primary"
                        ref="sbmt"
                      >
                        <FormattedMessage id="submit" defaultMessage="SUBMIT" />
                      </button>
                      {/* <input type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" value="SUBMIT"  ref="sbmt"/> */}
                    </div>
                    <div className="status"></div>
                  </div>
                  {/* <div className="col-lg-2 col-md-12 d-flex flex-column p-0 m-0 text-center">
                    <ul className="list-unstyled mb-0">
                      <li>
                        <i className="fa fa-map-marker"></i>
                        <p>4 rue des Fosses Saint-Jacques 75005 PARIS</p>
                      </li>

                      <li>
                        <i className="fa fa-phone"></i>
                        <p>+ 33 (0) 9 83 20 00 15</p>
                      </li>

                      <li>
                        <i className="fa fa-envelope"></i>
                        <p>Contact@pilotrh.com</p>
                      </li>
                    </ul>
                  </div> */}
                </form>
              )}
            </div>
            {/* <iframe className="row w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.6465398876257!2d2.341217015569272!3d48.845880409541635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e671c2abd69d35%3A0x22ed031f8e1bea4a!2s4+Rue+des+Foss%C3%A9s+Saint-Jacques%2C+75005+Paris%2C+France!5e0!3m2!1sen!2sin!4v1562656442595!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" allowFullScreen></iframe> */}
          </div>
        </section>

        <section className="client-info-block" id="info-block">
          <div className="container">
            <div className="row">
              <div class="col-md-12">
                <div className="Home-btn">
                  <Link
                    className="btn btn-primary p-link client-info-block"
                    style={{
                      padding: "10px 10px",
                      pointerEvents: "none",
                    }}
                  >
                    <div class="client-btn">
                      <img
                        className="oliver-img"
                        src="assets/images/olivier-Colson.jpg"
                      ></img>
                      <b>
                        <p style={{ marginBottom: "0px", color: "black" }}>
                          {" "}
                          <FormattedHTMLMessage
                            id="home.titlebtn"
                            defaultMessage="FAQ"
                          />
                        </p>
                      </b>
                    </div>
                  </Link>
                  <a href="https://calendly.com/plateforme-pilotrh-evaluation-des-talents">
                    <button className="info-btn">
                  
                      <FormattedHTMLMessage
                        id="home.title.btn"
                        defaultMessage="FAQ"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

const validate = (values) => {
  //console.log('Inside validate');
  const errors = {};
  if (!values.name) {
    errors.name = "Name is required";
  }
  if (!values.email) {
    errors.email = "Email address is required";
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.username = "Invalid email";
  }
  // if (!values.subject) {
  //    errors.subject = "Subject is required";
  // }
  if (!values.message) {
    errors.message = "Message is required";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    contactUsValidation: state.form.contactUsValidation,
    contactUsError: state.auth.contactUsError,
    contactUsMessage: state.auth.contactUsMessage,
    lang: state.locale.lang,
  };
};

PreRegistration = connect(mapStateToProps, { contact, clearContactMessage })(
    PreRegistration
);

export default reduxForm({
  form: "contactUsValidation",
  enableReinitialize: true,
  validate,
})(PreRegistration);

//export default Contactus;
