import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "./includes/Footer";
import { Field, reduxForm } from "redux-form";
import { FormattedMessage } from "react-intl";
import Hash from "../helper/Hashing";
import axios from "axios";
import { ROOT_URL, APP_URL } from "../helper/Config";

import { shareTestLink, clearSharelinkMessage } from "../actions";

import $ from "jquery";

class CreateLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          error: false,
          errorMessage: "",
          testUrl : "",
          message : "",
          remainingTest: 0,
          both:null,
          chose : "",
          prof : "",
          candidate : "",
          both : "",
          spam:""
        };
        window.scrollTo(0, 0); // scroll page to top
        this.shareLinkForm = this.shareLinkForm.bind(this);
        this.handleforgetemailChange = this.handleforgetemailChange.bind(this);
        
        if(this.props.lang === "en") {
          this.state.chose = "Choose";
          this.state.prof = "Professional only";
          this.state.candidate = "Candidate Only";
          this.state.both = "Both";
          this.state.spam="(Be carefull first send possible in spams)";
        }
        if(this.props.lang === "fr") {
          this.state.chose = "Choose...";
          this.state.prof = "Le Professionnel seulement";
          this.state.candidate = "Le Candidat seulement";
          this.state.both = "Les deux";
          this.state.spam="(Attention 1er envoi possible reçu en spams)";
        }
 
 
 
      }
  handleforgetemailChange(e) {
   this.setState({message: e.target.value});
  }
    componentDidMount() {
      if (this.props.isSignedIn) {
        var userid = localStorage.getItem("userId");
        //console.log("user loggedIN "+userid);
        var api_url = `${ROOT_URL}/getTests/${userid}`;
        // get no of users test
        axios.get(api_url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }).then(response => {
          //console.log('Inside componentDidMount '+response.data.tests);
          this.setState({
            remainingTest: response.data.tests
          });
        }).catch(error => {
          console.log(error);
        });
      }
     
    }

    shareLinkForm(values) {
      console.log(values)
      let { testUrl } = this.state; 
      let hrId = localStorage.getItem("userId");
      let encryptId = this.Encrypt(hrId);
      //console.log('HrId => '+ hrId +'  == '+ this.Decrypt(encryptId));   
      // cteate test url
      if (values.testid === "1") testUrl = `${APP_URL}happiness-link/${encryptId}/${values.option}`;
      if (values.testid === "2") testUrl = `${APP_URL}coaching-tool/${encryptId}/${values.option}`;
      if (values.testid === "5") testUrl = `${APP_URL}recruitment-link/${encryptId}/${values.option}`;
      if (values.testid === "4") testUrl = `${APP_URL}sale-force/${encryptId}/${values.option}`;
      if (values.testid === "3") testUrl = `${APP_URL}creation-link/${encryptId}/${values.option}`;

      // if (values.testid === "1") testUrl = `${APP_URL}happiness/${encryptId}`;
      // if (values.testid === "2") testUrl = `${APP_URL}coaching-tool/${encryptId}`;
      // if (values.testid === "5") testUrl = `${APP_URL}recruitment/${encryptId}`;
      // if (values.testid === "4") testUrl = `${APP_URL}sale-force/${encryptId}`;
      // if (values.testid === "3") testUrl = `${APP_URL}creation/${encryptId}`;
      // assign value to submit
      values['language']  = this.props.lang;
      values['message']  = this.state.message;
      values['userId']    = hrId;
      values['encryptId'] = encryptId;
      values['test_url']  = testUrl;

      //console.log('Init submit form'+JSON.stringify(values)+' lang'+this.props.lang);
      //{"name":"test","email":"test@gmail.com","message":"asdfsdfsdfdsf"}
      $("#sharelink-loader").show();
      this.refs.sbmt.setAttribute("disabled", "disabled");
        //console.log('Inside shareLinkForm '+values.email);
        this.props.shareTestLink(values, this.refs.sbmt, response => {
          console.log('Response '+response);
          if (response === "success") {
            this.props.reset("shareLinkValidation");
            // on successfull signup show login form
            $("#sharelink-loader").hide();
            // after successfully submit redirect to respective page
            setTimeout(function(){ window.location = "/"; }, 20000);
          } else {
            this.setState({
              error: true,
              errorMsg: "Error occur during share test link!"
            });
          }
          this.setState({ loading: false });
        });
    }

    renderInput(field) {
      //console.log('field.label=> '+field.label+' touched '+field.meta.touched+' error '+field.meta.error);
      return (
        <div className="form-group">
          <label>{field.label}</label>
          <input
            type={field.type}
            className="form-control"
            placeholder={field.placeholder}
            autoComplete="off"
            {...field.input}
          />
          {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
          )}
        </div>
      );
    }


    renderSelectField = (field) => (
      
      //console.log('==> '+JSON.stringify(field)+' field.input '+field.input+' meta.touched '+field.meta.touched+' meta.touched '+field.meta.error);
      <div className="form-group">
        <label>{field.label}</label>
        <select {...field.input} className="form-control">
          <option value="">Choose...</option>
          <option value="1">Happiness tool</option>
          <option value="2">Coaching tool</option>
          <option value="5">Recruitment tool</option>
          <option value="3">Business creation tool</option>
          <option value="4">Sales tool</option>
        </select>
        {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
  
          )}
      </div>
    );
    
    renderInputoption = (field) => (
      //console.log('==> '+JSON.stringify(field)+' field.input '+field.input+' meta.touched '+field.meta.touched+' meta.touched '+field.meta.error);
      <div className="form-group">
        <label>{field.label}</label>
        <select {...field.input} className="form-control">
          <option value="">{this.state.chose}</option>
          <option value="0">{this.state.prof}</option>
          <option value="3">{this.state.candidate}</option>
          <option value="2">{this.state.both}</option>
        </select>
        {/* {field.meta.touched && field.meta.error && (
            <small className="text-danger">{field.meta.error}</small>
          )} */}
      </div>
    );


    // changeTest(ev) {
    //   this.setState({ test: ev.target.value, testLink: ev.target.value, sendTo: ev.target.value });
    // }

    Encrypt = id => {
      //var ciphertext = CryptoJS.AES.encrypt(id, HASH_KEY);
      var ciphertext = Hash.encrypt(id);
      return ciphertext;
    };

    Decrypt = id => {
      //var ciphertext = CryptoJS.AES.encrypt(id, HASH_KEY);
      var ciphertext = Hash.decrypt(id);
      return ciphertext;
    };

    errorMessage() {
      //console.log(this.props.shareLinkError+' --  '+this.props.shareLinkMessage+' props '+JSON.stringify(this.state));
      if (this.props.shareLinkError !== null && this.props.shareLinkError !== "") {
        return (
          <div className="col-md-12 alert alert-danger" role="alert">
            {this.props.shareLinkError}
          </div>
        );
      }
      if (
        this.props.shareLinkMessage !== null &&
        this.props.shareLinkMessage !== ""
      ) {
        return (
          <div className="col-md-12 alert alert-success" role="alert">
            {this.props.shareLinkMessage}
          </div>
        );
      }
    }

  render() {
    //const { intl } = this.props;
    //console.log(this.props.shareLinkError+' --  '+this.props.shareLinkMessage+' props '+JSON.stringify(this.state));
    //const jobProfile  = <FormattedMessage id="share.link.jobprofile" defaultMessage="Job Profile Name *"/>
    let { remainingTest } = this.state;
    //console.log('render remainingTest '+remainingTest);
    const emailaddress  =<p><FormattedMessage id="share.link.sendto" defaultMessage="Share with *"/><span style={{color:"#00B4D8"}}>{this.state.spam}</span></p>
    const selecTest  = <FormattedMessage id="share.link.selecttest" defaultMessage="Preference *"/>
    const personal  = <FormattedMessage id="personal.message" defaultMessage="Preference *"/>
    const selecOption  = <FormattedMessage id="share.link.selectoption" defaultMessage="Who can see results:"/>
    return (
    <React.Fragment>
      <section className="mb-4 pages">
        <div className="container">
          <div className="row d-flex p-0 m-0">
            <div className="col-lg-6 col-md-8 m-auto">
                {this.props.shareLinkMessage === null ? (
                  <div className="col-md-12">
                    <h2 className="h1-responsive font-weight-bold text-center my-4">
                      <FormattedMessage id="create_link_title" defaultMessage="Create Link"/>
                    </h2>
                  </div>
                ) : ( '' )}

                <div className="card-div">
                  <div className="row">
                    {this.props.shareLinkMessage != null ? (
                      <div className="col-md-12 p-4 m-0 text-center">
                        {this.errorMessage()}
                        <div className="col-sm-12 mx-auto text-center mb-4 pb-45">
                          <Link to="/" className="btn btn-warning mt-3 mx-3">
                            <FormattedMessage id="goto_home" defaultMessage="HOME PAGE"/>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <form id="sharelink-form" onSubmit={this.props.handleSubmit(e => this.shareLinkForm(e))} className="row w-100">
                        {/* <div className="col-md-12">
                          <div className="form-group">
                              <div className="md-form mb-0">
                                  <Field name="jobProfile" type="text" label={jobProfile} placeholder="Job Profile Name"  className="form-control"  component={this.renderInput}/>
                              </div>
                          </div>
                        </div> */}
                        <div className="col-md-12">
                          <div className="form-group">
                  
                              <div className="md-form mb-0">
                                  <Field name="email" type="email" label={emailaddress} placeholder="example@gmail.com"  className="form-control"  component={this.renderInput}/>
                              </div>
                              {/* <div className="md-form mb-0">
                          <h6 className="h1-responsive my-4" style={{color:"red"}}>
                      <FormattedMessage id="spam_msg" defaultMessage="Create Link"/>
                    </h6>
                              </div> */}
                              <div className="md-form mb-0">
                     
                            
                              </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          {/* <Field name="testid" component={this.renderSelectField} label={selecTest} onChange={e => this.changeTest(e)} /> */}
                          <Field name="testid" component={this.renderSelectField} label={selecTest} />
                        </div>
                        <div className="col-md-12">
                          {/* <Field name="testid" component={this.renderSelectField} label="{personal.message}" onChange={e => this.changeTest(e)} /> */}
                         <div className="form-group">
                         <div className="md-form mb-0">
                                  <Field name="option"  label={selecOption} placeholder="choose.."  className="form-control"  component={this.renderInputoption}/>
                              </div>
                              <label>{personal}</label>
                              <textarea
                              className="form-control"
                              onChange={this.handleforgetemailChange}
                               name="message"
                              >
                              </textarea>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="spinner-border text-primary m-auto" role="status" id="sharelink-loader">
                              <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            {remainingTest === '0' ? (
                              <div className="col-md-12">
                                <p className="text-center my-4">
                                  <FormattedMessage id="share.link.enoughtest" defaultMessage="You dont have enough test to share test link please buy few now."/>
                                </p>
                                <Link to="/buy-test" className="btn btn-primary mt-3 mx-3">
                                  <FormattedMessage id="header.buy.test" defaultMessage="BUY TEST"/>
                                </Link>
                              </div>
                            ) : (
                              <button type="submit" name="send-query" id="send-query" tabIndex="4"  className="btn btn-primary" ref="sbmt">
                                <FormattedMessage id="share.link.button" defaultMessage="SHARE TEST"/>
                              </button>
                            )}
                        </div>
                        <div className="status"></div>
                      </form>
                    )}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
    );
  }
}

const validate = values => {
  //console.log('Inside validate'+values.email);
  //console.log('Inside validate'+JSON.stringify(values)+' testid '+values.testid);
  console.log(values);
    const errors = {};
    if (!values.testid) {
      errors.testid = "Please select test  it is required.";
    }
    // if (!values.jobProfile) {
    //   errors.jobProfile = "Please fill the name of job profile.";
    // }
    if (!values.email) {
      errors.email = "Email address is required.";
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      errors.email = "Invalid email";
    }
    return errors;
  };

const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    shareLinkValidation: state.form.shareLinkValidation,
    shareLinkError: state.auth.shareLinkError,
    shareLinkMessage: state.auth.shareLinkMessage,
    lang: state.locale.lang
  };
};

CreateLink = connect(
  mapStateToProps,
  { shareTestLink, clearSharelinkMessage }
)(CreateLink);


export default reduxForm({
    form: "shareLinkValidation",
    enableReinitialize: true,
    validate
  })(CreateLink);

//export default CreateLink;

