import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//import $ from "jquery";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Carousel from "react-bootstrap/Carousel";
import Footer from "./includes/Footer";
import Register from "./includes/Register";
import { history } from "../helper/History";
import $ from "jquery";
import { bindActionCreators } from "redux";

//import { FormattedMessage } from "react-intl";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dis: "",
      display: "null",
    };
    window.scrollTo(0, 0); // scroll page to top
  }

  banner() {
    return (
      <div className="alert-block">
        <div
          class="alert alert-dark alert-dismissible fade show "
          style={{ color: "white", backgroundColor: "black" }}
          role="alert"
        >
          <FormattedHTMLMessage id="banner" defaultMessage="FAQ" />
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }

  showRegister(e) {
    var userType = localStorage.getItem("role");
    if (!userType) {
      history.push("/register");
      // console.log('in '+e);
      // $("#loginModal").modal("show");
      // $("#register-form").delay(100).fadeIn(100);
      // $("#login-form").fadeOut(100);
      // $("#login-form-link").removeClass("active");
      // console.log('Inside Header '+e);
      // if(e === '#register-form-link'){
      //   $('.modal-title').text('SIGNUP');
      //   $(e).addClass("active");
      // }else{
      //   $(e.target).addClass("active");
      // }
    }
  }
  showRegister2(e) {
    console.log("in " + e);
    $("#loginModal").modal("show");
    $("#register-form").delay(100).fadeIn(100);
    $("#login-form").fadeOut(100);
    $("#login-form-link").removeClass("active");
    console.log("Inside Header " + e);
    if (e === "#register-form-link") {
      $(".modal-title").text("SIGNUP");
      $(e).addClass("active");
    } else {
      $(e.target).addClass("active");
    }
  }

  render() {
    return (
      <React.Fragment>
      
  
      <section className="position-relative top-100">
        <div className="col-lg-12 col-md-12 pl-0 pr-0 home-background">
            <div className="col-lg-3 col-md-3" style={{ float:"left",marginTop:"3%"}}>
              <div className="row">
                  <p className="client-info-btn text-center" style={{ marginRight:"0px" }}>
                    {" "}
                    <FormattedHTMLMessage
                      id="home.titlebtn2"
                      defaultMessage="FAQ"
                    />
                  </p>
              </div>
            </div>
           
            <div className="col-lg-4 col-md-4" style={{ float:"left",marginTop:"10%",marginLeft:"2%"}}>
              <div className="">
                <div className="blue-box" style={{ backgroundColor: "#18B6CE",marginTop:"100px"}}>
                    <div className="banner-logo">
                      <img className="center-logo" src="assets/images/LOGO1.png"></img>
                    </div>
                    <div className="blue-text-box">
                      <h2 className=" animate animated slideInLeft text-center">
                        <FormattedHTMLMessage id="homepageslider" defaultMessage="TESTS FOR INDIVIDUAL"/>
                      </h2>
                    </div>        
                </div>
                <div className="">
                <div className="indi-tests-home" >
                  <ul className="services-list" style={{ top:"0" }}> 
                  <li className="mr-4 mb-4" style={{ backgroundColor: "#28326a", border: "none", width:"100%" }}>
                    <Link to="/youareaconsultant">
                      <button
                        data-toggle="modal"
                        data-target=""
                        className="btn"
                        style={{ color: "white" }}
                      >
                        <FormattedHTMLMessage
                          id="sub.menue1"
                          defaultMessage="For Individual"
                        />
                      </button>
                    </Link>
                  </li>
                  <li className="mb-4"style={{ backgroundColor: "#28326a", border: "none", width:"100%" }}>
                    <Link to="/youareacompany">
                      <button
                        data-toggle="modal"
                        data-target=""
                        className="btn"
                        style={{ color: "white" }}
                      >
                        <FormattedHTMLMessage
                          id="sub.menue2"
                          defaultMessage="For Individual"
                        />
                      </button>
                    </Link>
                  </li>

                  {/* <li style={{ backgroundColor: "#28326a", border: "none" }}>
                    <Link to="/individual">
                      <button
                        data-animation="fadeIn"
                        data-toggle="modal"
                        data-target=""
                        className="btn"
                        style={{ color: "white" }}
                      >
                        <FormattedHTMLMessage
                          id="sub.menue3"
                          defaultMessage="For Individual"
                        />
                      </button>
                    </Link>
                  </li> */}
                 </ul>
                </div>


                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4" style={{ float:"left",marginTop:"25px", marginLeft:"1%"}}>
                <div className="row">
                  <div class="show-desktop" style={{ background:"#28326a", width:"100%" }}>
                    <div class="client-btn ">
                      <video class="oliver-img" controls loop style={{width: "89%", height:"150px",  marginLeft: "6%"}} controls>
                        <source src="assets/video/IMG_7284.mov" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <button class="info-btn mt-3 text-center" style={{ fontWeight:"700"}}> 
                      
                      <span><FormattedHTMLMessage
                          id="home.video.button"
                          defaultMessage="Note"
                        /></span>

                    </button>
                  
                  </div>
                
                  <div className="show-desktop mt-3" >
                    <a href='/pre-registration' style={{color:'#fff'}}>
                        <div className="client-info-title-home text-center" style={{ marginLeft:"0%", width:"100%" }}> 
                          <FormattedHTMLMessage id="home.pre-registration" defaultMessage="Pre"/> 
                        </div>
                    </a>
                  </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4" style={{ float:"left",marginTop:"25px", marginLeft:"1%"}}>
                <div className="row">
                
                  <div className="show-desktop ml-4" style={{ background:"#28326a", width:"100%" }}>
                    <Link className="btn btn-primary p-link client-info-block" style={{ background: "white",height:"178px",width:"100%",}}>
                      <div class="client-btn">
                        <b> <p style={{ marginBottom: "0px", color: "black" }}>
                            {" "}
                            <FormattedHTMLMessage
                              id="home.titlebtn"
                              defaultMessage="FAQ"
                            />
                          </p> 
                        </b>
                        <img
                          className="oliver-img"
                          src="assets/images/olivier-Cols.jpg"
                        ></img>
                      </div>
                    </Link>
                    <a href="https://calendly.com/plateforme-pilotrh-evaluation-des-talents">
                      <button className="info-btn">
                        {" "}
                        <FormattedHTMLMessage
                          id="home.title.btn"
                          defaultMessage="FAQ"
                        />
                      </button>
                    </a>
                  </div>
                
                  <div className="show-desktop mt-3 ml-3" >
                    <a href='/contact-us' style={{color:'#fff'}}>
                      <div className="client-info-title ml-2 text-center" style={{ fontSize:"10px", float:"left", padding:"9px 54px", width:"100%" }}> 
                        <FormattedHTMLMessage id="home.trial10days" defaultMessage="Note"/> 
                      </div>
                    </a>
                  </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 mt-4" style={{ float:"left",marginLeft:"2%" }}>
                  <div className="show-desktop mt-3 home-text"  style={{ width:"97%" }}>
                    <div className="client-info-home"> 
                      <FormattedHTMLMessage id="home.textnote" defaultMessage="Note"/>
                    </div>
                  </div>
                  <div className="show-desktop mt-3 home-text"  style={{ width:"97%", background:"#fff" }}>
                    <div className="client-info-home ml-4"> 
                      <FormattedHTMLMessage id="home.textnote.footer" defaultMessage="Note"/>
                    </div>
                  </div>
            </div>
        </div>
      </section>
     
      <section className="client-info-block" id="info-block">
        <div className="container">
          <div className="row">
            <div class="col-md-12">
              <div className="Home-btn">
                <Link
                  className="btn btn-primary p-link client-info-block"
                  style={{
                    padding: "10px 10px",
                    pointerEvents: "none",
                  }}
                >
                  <div class="client-btn">
                    <img
                      className="oliver-img"
                      src="assets/images/olivier-Colson.jpg"
                    ></img>
                    <b>
                      <p style={{ marginBottom: "0px", color: "black" }}>
                        {" "}
                        <FormattedHTMLMessage
                          id="home.titlebtn"
                          defaultMessage="FAQ"
                        />
                      </p>
                    </b>
                  </div>
                </Link>
                <Link to="https://calendly.com/plateforme-pilotrh-evaluation-des-talents">
                  <button className="info-btn">
                    {" "}
                    <FormattedHTMLMessage
                      id="home.title.btn"
                      defaultMessage="FAQ"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
